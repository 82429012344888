<!--
 * @Author: lbh
 * @Date: 2021-04-28 18:49:27
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-27 15:17:40
 * @Description: 大喜屋集團
-->
<template>
  <div
    class="gsq-box t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="case"
      child='daxiwu'
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        :title="$t('daxiwu.bigTitle')"
        :type="1"
        :wtype="window.type"
        :image="getImgPath('case.jpg')"
      />
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :datas="graphic.datas"
        :height="graphic.height"
        :title="$t('daxiwu.title')"
        :bImg="graphic.bImg"
        bImgP="left"
      />
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfGraphic from "@/components/commo/graphic";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfGraphic
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      graphic: {
        imgs: [],
        datas: [],
        height: 0,
        bImg: ''
      },
      bgImage: ''
    }
  },
  mounted () {
    util.setTitle(`${this.$t('daxiwu.title')} – ${this.$t('companyName')}`);
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    // 初始化數據
    let imgs = [
      util.getImgPath('001-1-500x403.png')];
    this.graphic.imgs = imgs;
    let datas = [
      {
        title: this.$t('daxiwu.labelA.title'),
        items: [
          { title: this.$t('daxiwu.labelA.itemA'), style: 'margin-top:25px' },
          { title: '', style: 'height:1px;background-color:#000;margin-top:35px;' }
        ]
      },
      {
        title: this.$t('daxiwu.labelB.title'), items: [
          { title: this.$t('daxiwu.labelB.itemA'), style: 'margin-top:25px' },
          { title: this.$t('daxiwu.labelB.itemB') },
          { title: `- ${this.$t('daxiwu.labelB.itemC')}` },
          { title: `- ${this.$t('daxiwu.labelB.itemD')}` },
        ],
      },
    ];
    this.graphic.datas = datas;
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 5);
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 2.5;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 2.5;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else {
        this.window.type = '1000';
        this.graphic.height = (w * 0.8 / 2 - 40) * 0.8;
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
</style>
